import { Box } from '@mui/material';
import "../../stylesheets/chat_page.css"
import { useState, useEffect } from 'react';
import { isDarkMode, get_max_watchlist_items } from '../utils/constants';
import WatchlistLine from '../../javascript/images/WatchlistLine.png'
import WatchlistUpdate from '../../javascript/images/WatchlistUpdate.png'
import { updateUserWatchlist, refreshWatchlist } from '../api/user';
import { isMobileBrowser } from '../utils/user_agent';
import { watchlistPaywallModalMessage } from '../utils/constants';

const WatchlistModal = ({ setWatchlistVisible, modalRef, watchlistData, setWatchlistData, onQuestionSubmit, lastRequestedUpdateWatchlist, setLastRequestedUpdateWatchlist, isRefreshing, setIsRefreshing, hasErroredWatchlist, setHasErroredWatchlist, userData, setPaywallMessage, setShowPaywallModal, }) => {

  const isMobile = isMobileBrowser()

  const [opacity, setOpacity] = useState(0)
  const [isEditing, setIsEditing] = useState(false)
  const [tickersSearched, setTickersSearched] = useState([])
  const [updatedTime, setUpdatedTime] = useState(null)
  const [numWatchlistItems, setNumWatchlistItems] = useState(null)
  const [watchlistCountError, setWatchlistCountError] = useState(false)

  const [searchQuery, setSearchQuery] = useState(""); // State to keep track of the search input

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value.length >= 2) { // Consider triggering search when the query length is more than 2 characters
      triggerSearch(event.target.value);
    } else {
      setTickersSearched([])
    }
  };

  const triggerSearch = async (query) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({query: `
        {
          searchTickers(searchTerm: "${query}") {
            ticker
            name
            id
          }
        }`
      })
    };

    fetch('/graphql', requestOptions)
    .then(response => response.json())
    .then(data => {
      setTickersSearched(data.data.searchTickers)
    })
    .catch(error => console.error('Error fetching data:', error));
  };

  const addNewTicker = (id) => {
    let newNumWatchlistItems = numWatchlistItems + 1
    if (newNumWatchlistItems > get_max_watchlist_items(userData)) {
      setWatchlistCountError(true)
    } else {
      setNumWatchlistItems(newNumWatchlistItems)
      setSearchQuery("")
      setLastRequestedUpdateWatchlist(null) // Set this back to null to allow it to update again
      updateUserWatchlist([id], null, setWatchlistData)
    }
  }
  const removeNewTicker = (id) => {
    setNumWatchlistItems(numWatchlistItems-1)
    setWatchlistCountError(false)
    updateUserWatchlist(null, id, setWatchlistData)
  }


  useEffect(() => {
    setOpacity(1.0)
  }, [])

  useEffect(() => {
    if (!isRefreshing && !hasErroredWatchlist && !isEditing) {
      const now = new Date(); 
      // Based on the data here, look for earliest update time...
      let updateTimes = []
      let tickerIdsNullAction = []
      let itemCount = 0
      Object.keys(watchlistData).forEach(key => {
        watchlistData[key].forEach(item => {
          itemCount += 1;
          if (!item.change || !item.action) {
            tickerIdsNullAction.push(item.id)
          }
          if (item.updatedAt) {
            // Ensure updatedAt is a valid date and push as a Date object
            const updatedTime = new Date(item.updatedAt);
            updateTimes.push(updatedTime);
            // Check if more than one hour has passed since updatedAt
            if ((now - updatedTime) > 8*3600000) { // 3600000 milliseconds in an hour. So, 8 hour wait!
              tickerIdsNullAction.push(item.id);
            }
          }
        })
      })
      if (tickerIdsNullAction.length > 0) {
        const refreshProps = {lastRequestedUpdateWatchlist, setLastRequestedUpdateWatchlist, setIsRefreshing, setWatchlistData, setHasErroredWatchlist}
        setIsEditing(false)
        refreshWatchlist(refreshProps, tickerIdsNullAction, true)
      }
      if (updateTimes.length > 0) {
        const earliestUpdateTime = new Date(Math.min(...updateTimes)); // Find the earliest time
        // Format the date and time nicely, e.g., "January 1, 2020, 12:00 AM"
        setUpdatedTime(earliestUpdateTime.toLocaleString('en-US', {
          // /year: 'numeric', 
          month: 'long',
          day: 'numeric', 
          hour: '2-digit', minute: '2-digit'
          // , second: '2-digit'
        }));
      }
      setNumWatchlistItems(itemCount)
    }
  }, [watchlistData, isRefreshing, hasErroredWatchlist, isEditing])


  // Will automatically refresh every hour that there is a new session
  // Watchlist fully accessable and editable to everyohne.
  // Limit to 2 queries a week.
  // Paywall Modal bring back (And update from figma)

  let classPlaceholderInput = isDarkMode ? "placeholder-input dark" : "placeholder-input"

  const textString = isDarkMode ? "base-text dark watchlist-text" : "base-text watchlist-text";

  return (
    <div className="modal-watchlist" style={{
      opacity: opacity,
      transition: 'opacity 0.5s', padding: 0, margin: 0
      }}>
      <Box className="watchlist-modal" ref={modalRef} sx={{backgroundColor: isDarkMode ? '#000000' : '#F6F6F6', borderColor: isDarkMode ? '#393939':'#F6F6F6'}}>
        <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '14px'}}>
          
          <Box className="base-text" sx={{position: 'absolute', left: '15px', fontWeight: '600', fontSize: '15px', color: isRefreshing ? 'gray':'#2971D7', '&:hover': {cursor: isRefreshing ? 'not-allowed' : 'pointer'}}}
            onClick={() => {
              // if (userData && userData.has_active_subscription) {
              if (userData && (userData.has_active_subscription || userData.is_admin_user)) {
                if (!isRefreshing) {
                  setIsEditing(!isEditing)
                }
              } else {
                // Show paywall with custom message
                setPaywallMessage(watchlistPaywallModalMessage)
                setShowPaywallModal(true)
              }
            }}
          >
            {isEditing ? "Done" : "Edit"}
          </Box>

          <Box className="base-text" sx={{
            fontWeight: '500', fontSize: '15px', color: '#F6F6F6',
            color: isDarkMode ? '#F6F6F6' : '#1D1D1F'
          }}>
            Watchlist
          </Box>

          { isMobile &&
            <Box className="base-text" sx={{position: 'absolute', right: '15px', fontWeight: '600', fontSize: '15px', color: '#2971D7', '&:hover': {cursor: 'pointer'}}} onClick={() => setWatchlistVisible(false)}>
              {"Close"}
            </Box>
          }

        </Box>

        <Box sx={{overflowY: 'scroll', maxHeight: '44vh'}}>

        {Object.keys(watchlistData).map((key) => {
          return (
            <Box sx={{ color: '#F6F6F6', padding: '16px 0px 0px 16px' }}>
              { watchlistData[key].length > 0 &&
                <Box sx={{ display: 'flex', marginBottom: 2 }}>
                  <Box className={textString} sx={{ width: '55%', fontWeight: 'bold', textAlign: 'left', fontSize: '18px' }}>{key}</Box>
                  {/* <Box className={textString} sx={{ width: '20%', textAlign: 'center', fontWeight: 'bold', fontSize: '10px' }}>CHANGE</Box> */}
                  <Box className={textString} sx={{ width: '15%', minWidth: '70px', textAlign: 'center', fontWeight: 'bold', fontSize: '10px' }}>PRICE</Box>
                  <Box className={textString} sx={{ width: '15%', minWidth: '55px', textAlign: 'center', fontWeight: 'bold', fontSize: '10px' }}>CHANGE</Box>
                  <Box className={textString} sx={{ width: '15%', minWidth: '50px', textAlign: 'center', fontWeight: 'bold', fontSize: '10px' }}>ACTION</Box>
                </Box>
              }
              {watchlistData[key].map((item) => (
                <Box className={textString} sx={{ display: 'flex', lineHeight: '167%', marginBottom: '4px', '&:hover': { cursor: 'pointer' } }}
                onClick={() => {
                  if (!isEditing) {
                    setWatchlistVisible(false)
                    setTimeout(() => {
                      onQuestionSubmit(`Analyze ${item.ticker} ${item.action}`, null, item.id)
                    }, 400)
                  }
                }}
                >
                  {isEditing && (
                      <Box sx={{margin: '4px 6px 0px 0px', width: '18px', height: '16px', backgroundColor: 'rgba(255,0,0,.9)', borderRadius: '50%', position: 'relative',
                        '&:hover': { cursor: 'pointer' }
                      }} onClick={() => removeNewTicker(item.id)}
                      >
                        <Box sx={{width: '10px', height: '2px', backgroundColor: 'white', position: 'absolute', left: '3px', top: '7px', borderRadius: '1px'}}>
                        </Box>
                      </Box>
                    )}
                  <Box className={textString} sx={{ width: '55%', lineHeight: '167%' , textAlign: 'left',}}>{item.name} ({item.ticker})</Box>
                  {/* <Box className={textString} sx={{ width: '20%', lineHeight: '167%' , textAlign: 'center' }}>{item.change && `${Math.round(10000*item.change)/100}%`}</Box> */}
                  <Box className={textString} sx={{ width: '15%', minWidth: '70px', lineHeight: '167%' , textAlign: 'center' }}>${item.currentPrice}</Box>
                  { (key == "Stocks") ?
                    <Box className={textString} sx={{ width: '15%', minWidth: '55px', lineHeight: '167%' , textAlign: 'center' }}>{item.pricePctChange1d}</Box>
                    :
                    <Box className={textString} sx={{ width: '15%', minWidth: '55px', lineHeight: '167%' , textAlign: 'center' }}>{item.pricePctChange24hr}</Box>
                  }
                  <Box className={textString} sx={{ width: '15%', minWidth: '50px', lineHeight: '167%' , textAlign: 'center' }}>{item.action}</Box>
                </Box>
              ))}
            </Box>
          );
        })}
        </Box>


        {isEditing && 
          // <Box sx={{ color: '#F6F6F6', padding: '10px 0px 10px 16px', textAlign: 'left', position: 'relative', height: '40px', }}>
          <Box sx={{ color: '#F6F6F6', margin: '0px 0px 0px 16px', textAlign: 'left', position: 'relative', height: '46px', width: '90%', }}>
            <input
              type="text"
              placeholder={`Search...`}
              value={searchQuery}
              onChange={handleSearchChange}
              onBlur={() => {
                setTimeout(() => {
                  setTickersSearched([]); // Timeout needed so that click will go through before it hides.
                }, 150);
              }}
              onFocus={(e) => {
                handleSearchChange(e)
              }}
              className={classPlaceholderInput}
              // style={{ width: '200px', height: '16px', margin: '0', padding: '8px', border: '1px solid #ccc' }}
              style={{ right: '0xp', height: '16px', margin: '0 0 0 0', padding: '8px 16px' }}
            />
            {tickersSearched.length > 0 &&
              <Box sx={{position: 'absolute', border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', left: '2px', right: '2px', top: '52px', borderRadius: '8px',  
              display: 'flex', flexDirection: 'column', gap: '6px', padding: '0px', }}>
              { tickersSearched.map((tickerItem) => {
                return (
                  <Box sx={{
                    border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', borderRadius: '8px', padding: '7px',
                    '&:hover': { cursor: 'pointer', backgroundColor: 'gray',  }
                  }}
                  onClick={() => addNewTicker(tickerItem.id)}
                    >
                    <span className={textString} style={{fontSize: '12px'}}>{tickerItem.name}</span> (<span className={textString} style={{fontSize: '12px'}}>{tickerItem.ticker}</span>)
                  </Box>
                )
              })
              }
              </Box>
            }
            { watchlistCountError &&
              <Box sx={{position: 'absolute', border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', left: '2px', right: '2px', top: '52px', borderRadius: '8px',  
              display: 'flex', flexDirection: 'column', gap: '6px', padding: '0px', }}>
                <Box sx={{
                    border: '#F6F6F6', backgroundColor: isDarkMode ? '#131521' : '#F6F6F6', borderRadius: '8px', padding: '7px',
                    // '&:hover': { cursor: 'pointer', backgroundColor: 'gray',  }
                  }}
                    >
                      <span className={textString} style={{fontSize: '12px'}}>
                      {`You can only have ${get_max_watchlist_items(userData)} items at a time in your watchlist. Please remove to add more.`}
                      </span>
                    {/* <span className={textString} style={{fontSize: '12px'}}>{tickerItem.name}</span> (<span className={textString} style={{fontSize: '12px'}}>{tickerItem.ticker}</span>) */}
                  </Box>
                
              </Box>
            }
            
          </Box>
        }

        <Box sx={{marginLeft: isDarkMode ? '0px':'-2px', marginRight: isDarkMode ? '-3px':'-1px', }}>
          <img src={WatchlistLine} style={{maxWidth: isDarkMode ? '101%':'102%'}} className={isRefreshing ? "fade-animation" : ""}/>
        </Box>

        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '16px'}}>
          <Box className="base-text" sx={{color: isDarkMode ? '#B7B7B7' : '#727279', fontSize: '12px'}}>
            Updated {updatedTime ? updatedTime : "---"}
          </Box>
          <Box sx={{'&:hover': {cursor: isEditing ? 'not-allowed' : 'pointer'}}} onClick={() => {
            if (!isEditing && !isRefreshing) {
              const refreshProps = {lastRequestedUpdateWatchlist, setLastRequestedUpdateWatchlist, setIsRefreshing, setWatchlistData, setHasErroredWatchlist}
              setIsEditing(false)
              refreshWatchlist(refreshProps, null, true)
            }
          }}>
            {/* <img src={WatchlistUpdate} style={{width: '25px', marginRight: '6px'}}/> */}
            <img src={WatchlistUpdate} style={{width: '25px', marginRight: '6px'}} className={isRefreshing ? "rotate-animation" : ""} />
          </Box>
        </Box>

        <Box className="base-text" sx={{padding: '0px 15px 0px 15px', textAlign: 'left', color: isDarkMode ? '#B7B7B7' : '#727279', fontSize: '10px'}}>
          <b>Disclaimer:</b> This analysis is for informational purposes only and should not be considered financial advice. Always consider your financial situation and consult with a financial advisor before making investment decisions.
        </Box>

      
      </Box>
    </div>
  )
}

const TickerItem = () => {
  
}

export default WatchlistModal
